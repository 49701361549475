import React from 'react'
import Layout from "../components/App/Layout"
import NavbarNoButton from "../components/App/NavbarNoButton" 
import Banner from "../components/Contact/Banner"
import Footer from "../components/App/Footer" 
import ContactForm from '../components/Contact/ContactForm'
const Contact = () => {
    return (
        <Layout>
            <NavbarNoButton />
            <Banner />
            <ContactForm />
            <Footer />
        </Layout> 
    );
}

export default Contact