import React, { useRef } from 'react';
import starIcon from '../../assets/images/star-icon.png'
import contact from '../../assets/images/contact.png'
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form = useRef();
    const name = useRef();
    const email = useRef();
    const phone_number = useRef();
    const message = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_v2p2cpa', 'template_d07wk8t', form.current, 'LcAOnp8foWXFMRXhk')
            .then((result) => {
                alert("Successfully submitted, thank you " + name.current.value + " for contacting ITSAS Team, we will respond accordingly.")
            name.current.value = '';
            email.current.value = '';
            phone_number.current.value = '';
            message.current.value = '';

            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
    };

    return (
        <section className="contact-area pb-100">
        <div className="container">
            <div className="section-title">
                <span className="sub-title">
                    <img src={starIcon} alt="contact" /> 
                    Get in Touch
                </span>
                <h2>Ready to Get Started?</h2>
                <p>Your email address will not be published. Required fields are marked *</p>
            </div>

            <div className="row">

                <div className="col-lg-8 col-md-10 offset-lg-2 offset-md-1">
                    <div className="contact-form">
                        <form id="contactForm" ref={form} onSubmit={sendEmail}>
                            <div className="row">
                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <input type="text" ref={name} name="name" className="form-control" required placeholder="Your name *" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-6">
                                    <div className="form-group">
                                        <input type="email" ref={email} name="email" className="form-control" required placeholder="Your email address *" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <input type="text" ref={phone_number} name="phone_number" className="form-control" required placeholder="Your phone number *" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <textarea name="message" ref={message} className="form-control" cols="30" rows="6" required placeholder="Write your message *" />
                                    </div>
                                </div>

                                <div className="col-lg-12 col-md-12 align-center">
                                    <button type="submit" className="default-btn" value="Send">
                                        <i className="flaticon-tick"></i> 
                                        Send Message <span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section> 
    )
}

export default ContactForm